<template>
	<div class="admin">
		<Header :user="user"/>
		<div class="admin__body">
			<form 
				action="" 
				class="export-form" 
				@submit.prevent="onExportData"
			>
				<h2 class="form-header">Export data</h2>
				<div class="input-container">
					<label for="from_link" class="label">From:</label>
					<input 
						type="text" 
						class="input"
						v-model="fromServer"
						disabled
					>
					<div class="server-status" v-if="fromServerStatus">Status: {{ fromServerStatus }}</div>
				</div>
				<div class="input-container">
					<label for="to_link" class="label">To:</label>
					<input 
						type="text"
						class="input"
						v-model="toServer"
						placeholder="Strapi server without / at the end"
						:disabled="exportLoader === true"
					>
					<div class="server-status" v-if="toServerStatus">Status: {{ toServerStatus }}</div>
				</div>
				<button
					type="submit"
					class="button"
					v-if="fromServerStatus === 'active' && toServerStatus === 'active' && !exportLoader"
				>Export</button>
				<img v-if="exportLoader" src="../assets/img/autosave_saving.gif">
				<div class="export-status" ref="export-status"></div>
			</form>
		</div>
	</div>
</template>

<script>
import Header from '@/components/Header';

export default {
	components: {
		Header
	},
	data: () => ({
		adminLogData: {
			identifier: 'admin@statica.pl'
		},

		fromServer: '',
		fromServerStatus: '',
		fromServerCheckTimeout: null,
		fromServerAuthToken: '',
		
		toServer: '',
		toServerStatus: '',
		toServerCheckTimeout: null,
		toServerAuthToken: '',

		exportLoader: false
	}),
	mounted() {
		const strapiHost = this.ENV_STRAPI_HOST; 
		if (strapiHost) {
			this.fromServer = strapiHost;
		}
		this.adminLogData.password = this.$store.getters.user.password;
	},
	methods: {
		async onCheckServerStatus(type) {
			if (type === 'fromServer') {
				const fromServer = this.fromServer;
				try {
					this.fromServerStatus = 'checking...';
					const response = await this.$store.dispatch('authServer', { 
						strapiLink: fromServer, 
						data: this.adminLogData 
					});
					if (fromServer === this.fromServer) {
						this.fromServerAuthToken = response.data.jwt;
						this.fromServerStatus = 'active';
					}
				} catch (e) {
					if (fromServer === this.fromServer) {
						console.log('Error: ', e);
						this.fromServerStatus = 'inaccessible';
					}
				}
			} else if (type === 'toServer') {
				const toServer = this.toServer;
				try {
					this.toServerStatus = 'checking...';
					const response = await this.$store.dispatch('authServer', { 
						strapiLink: toServer, 
						data: this.adminLogData 
					});
					if (toServer === this.toServer) {
						this.toServerAuthToken = response.data.jwt;
						this.toServerStatus = 'active';
					}
				} catch (e) {
					if (toServer === this.toServer) {
						this.toServerStatus = 'inaccessible';
					}
				}
			}
		},
		async processData(data) {
			const feedbackTypes = [
						'goodDefault', 'goodHover', 'goodActive', 
						'neutralDefault', 'neutralHover', 'neutralActive', 
						'badDefault', 'badHover', 'badActive'];
			// Fetch all files list
			const filesData = await this.$store.dispatch('fetchFiles', {
				strapiLink: this.fromServer,
				jwt: this.fromServerAuthToken
			});
			// Fetch assets list
			const assets = await this.$store.dispatch('fetchAssets', { 
				strapiLink: this.fromServer, 
				jwt: this.fromServerAuthToken 
			});
			// Process data, related images in assets and post processed assets
			let newAssets = [];
			for (let i = 0; i < assets.length; i++) {
				if (assets[i].name.startsWith('comboIcon')) {
					// Combo icon asset has only one related icon
					const imageUrl = assets[i].data.link;
					const fileData = filesData.find(f => f.url === imageUrl);
					if (!fileData) throw new Error('Error: Can\'t find ' + imageUrl + ' in files');
					const imageFile = await this.$store.dispatch('fetchImageByURL', { 
						strapiLink: this.fromServer,
						imageUrl,
						imageName: fileData.name,
						jwt: this.fromServerAuthToken
					});
					const newImage = await this.$store.dispatch('uploadImage', { 
						strapiLink: this.toServer, 
						imageFile, 
						imageName: fileData.name, 
						jwt: this.toServerAuthToken
					});	
					let newAsset = { ...assets[i], data: { ...assets[i].data, link: newImage.url } }
					newAsset = await this.$store.dispatch('postAsset', { 
						asset: newAsset,
						strapiLink: this.toServer, 
						jwt: this.toServerAuthToken
					});
					const oldHash = fileData.hash;
					newAsset.oldHash = oldHash;
					newAssets.push(newAsset);
				} 
				
				else if (assets[i].name.startsWith('feedbackIcons')) {
					let newAsset = { ...assets[i], data: { ...assets[i].data }, team: null }; 
					let oldHash = '';
					// Feedback has <=9 related icons 
					for (let j = 0; j < feedbackTypes.length; j++) {
						const imageUrl = assets[i].data[feedbackTypes[j]];
						if (!imageUrl.length) continue;
						const fileData = filesData.find(f => f.url === imageUrl);
						if (!fileData) throw new Error('Error: Can\'t find ' + imageUrl + ' in files');
						const imageFile = await this.$store.dispatch('fetchImageByURL', { 
							strapiLink: this.fromServer,
							imageUrl,
							imageName: fileData.name,
							jwt: this.fromServerAuthToken
						});
						const newImage = await this.$store.dispatch('uploadImage', { 
							strapiLink: this.toServer, 
							imageFile, 
							imageName: fileData.name, 
							jwt: this.toServerAuthToken
						});	
						newAsset.data[feedbackTypes[j]] = newImage.url;
						oldHash += fileData.hash;
					}

					newAsset = await this.$store.dispatch('postAsset', { 
						asset: newAsset,
						strapiLink: this.toServer, 
						jwt: this.toServerAuthToken
					});
					newAsset.oldHash = oldHash;
					newAsset.team = assets[i].team ? assets[i].team.id : null
					newAssets.push(newAsset);
				}
			}

			const imageLinksLog = []
			
			// Make relations: field -> new asset or image
			const fields = data.fields;
			for (let i = 0; i < fields.length; i++) {
				// Combo field with default icon has 'value'(string, contains image url) property, 
				// custom icon has 'image'(number, file id) property
				if (fields[i].type === 'combo' && fields[i].value) {
					const hash = fields[i].value.slice(9).split('.')[0];
					const relatedAsset = newAssets.find(a => a.oldHash === hash);
					if (!relatedAsset) throw new Error('Error: Can\'t find ' + fields[i].value + ' in assets');
					imageLinksLog.push({ 
						old: fields[i].value.replace('/uploads/', ''), 
						new: relatedAsset.data.link.replace('/uploads/', '') 
					});
					fields[i].value = relatedAsset.data.link;
				} 
				
				else if ((fields[i].type === 'combo' || fields[i].type === 'image') && fields[i].image && typeof fields[i].image === 'number') {
					// fields[i].image == image id
					const imageData = filesData.find(f => f.id === fields[i].image);
					if (!imageData) throw new Error('Error: Image with id ' + fields[i].image + ' was not found');
					const imageFile = await this.$store.dispatch('fetchImageByURL', {
						strapiLink: this.fromServer,
						imageName: imageData.name,
						imageUrl: imageData.url,
						jwt: this.fromServerAuthToken
					});
					const newImage = await this.$store.dispatch('uploadImage', {
						strapiLink: this.toServer,
						imageFile,
						imageName: imageData.name,
						jwt: this.toServerAuthToken
					});
					imageLinksLog.push({ 
						old: imageData.url.replace('/uploads/', ''), 
						new: newImage.url.replace('/uploads/', '')
					});
					fields[i].image = newImage.id;
				}

				else if (fields[i].type === 'feedback' && fields[i].value && typeof fields[i].value === 'string') {
					const assetId = fields[i].value.split('=')[1];
					const oldAsset = assets.find(f => f.id == assetId);
			
					if (!oldAsset) throw new Error('Error: Old asset with id ' + assetId + ' was not found');
					let oldHash = '';
					for (let j = 0; j < feedbackTypes.length; j++) {
						const link = oldAsset.data[feedbackTypes[j]];
						if (!link.length) continue;
						const currentLinkHash = link.slice(9).split('.')[0];
						oldHash += currentLinkHash;
					}
					const newAsset = newAssets.find(a => a.oldHash === oldHash);
					if (!newAsset) throw new Error('Error: New asset with hash ' + oldHash + ' was not found');
					fields[i].value = 'asset=' + newAsset.id;

					for (let j = 0; j < feedbackTypes.length; j++) {
						imageLinksLog.push({
							old: oldAsset.data[feedbackTypes[j]].replace('/uploads/', ''),
							new: newAsset.data[feedbackTypes[j]].replace('/uploads/', '')
						})
					}
				}
			}

			// Update app's image relations
			const apps = data.apps;
			for (let i = 0; i < apps.length; i++) {
				if (apps[i].image && typeof apps[i].image === 'number') {
					const imageData = filesData.find(f => f.id == apps[i].image);
					if (!imageData) throw new Error('Image with id ' + apps[i].image + ' was not found');
					const imageFile = await this.$store.dispatch('fetchImageByURL', {
						strapiLink: this.fromServer,
						imageName: imageData.name,
						imageUrl: imageData.url,
						jwt: this.fromServerAuthToken
					});
					const newImage = await this.$store.dispatch('uploadImage', {
						strapiLink: this.toServer,
						imageFile,
						imageName: imageData.name,
						jwt: this.toServerAuthToken
					});
					apps[i].image = newImage.id;
				}
			}

			// Update image links in exports
			const exports = data.exports;
			for (let i = 0; i < exports.length; i++) {
				for (let j = 0; j < imageLinksLog.length; j++) {
					if (imageLinksLog[j].old.length && exports[i].html.includes(imageLinksLog[j].old)) {
						exports[i].html = exports[i].html.replace(imageLinksLog[j].old, imageLinksLog[j].new);
					}
				}
			}

			data.assets = newAssets.map(a => {
				delete a.oldHash;
				return a;
			})
			return data;
		},
		async onExportData() {
			try {
				this.$refs['export-status'].innerHTML = '';
				this.exportLoader = true;
	
				const backupData = await this.$store.dispatch('getBackup', { 
					strapiLink: this.fromServer, 
					jwt: this.fromServerAuthToken });
	
				const processedData = await this.processData(backupData);
				console.log({ processedData });

				const postBackupResult = await this.$store.dispatch('postBackup', { 
					strapiLink: this.toServer, 
					jwt: this.toServerAuthToken, 
					data: processedData 
				});
	
				console.log({ postBackupResult });
	
				setTimeout(() => {
					this.$refs['export-status'].innerHTML = '';
				}, 3000);
				this.$refs['export-status'].innerHTML = 'Exported successfully';
			} catch(e) {
				this.$refs['export-status'].innerHTML = 'Error. Watch console';
				console.log('Error on export ', e);
			} finally {
				this.exportLoader = false;
			}
		}
	},
	computed: {
		user() {
			return this.$store.getters.user;
		}
	},
	watch: {
		fromServer() {
			clearTimeout(this.fromServerCheckTimeout);
			this.fromServerCheckTimeout = setTimeout(async () => {
				await this.onCheckServerStatus('fromServer');
			}, 500);
		},
		toServer() {
			clearTimeout(this.toServerCheckTimeout);
			this.toServerCheckTimeout = setTimeout(async () => {
				await this.onCheckServerStatus('toServer');
			}, 500);
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../assets/variables/variables'

.admin
	width: 1400px
	height: 100vh
	overflow: hidden
	margin: 0 auto
	&__body
		height: calc(100% - 156px)
		display: flex
		justify-content: center
		font-family: $lato
		color: #41494d
		.export-form
			width: 400px
			display: flex
			flex-direction: column
			align-items: flex-end
			margin-top: 150px
			.form-header
				margin-bottom: 40px
				width: 100%
				color: $grey-title
				text-transform: uppercase
				text-align: center
			.input-container
				margin-bottom: 20px
				width: 100%
				display: flex
				flex-wrap: wrap
				align-items: center
				.label
					font-size: 14px
					width: 50px
					text-align: right
					margin-right: 15px
				.input
					width: calc(100% - 50px - 15px)
					border: none
					background: transparent
					border-bottom: 1px solid $pink
					padding: 8px 0
					transition: .1s linear
					font-size: 14px
					font-family: $lato
					color: #41494d
					&:hover,
					&:focus
						border-color: $red
				.server-status
					width: 100%
					text-align: right
					font-size: 14px
					margin-top: 5px
			.button
				margin-top: 10px
				color: $pink
				font-size: 15px
				text-transform: uppercase
				border: none
				background: transparent
				transition: .1s linear
				font-family: $lato
				&:hover
					color: $red
					cursor: pointer
@media screen and (min-width: 1760px)
	.admin
		width: 1760px	

</style>